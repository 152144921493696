import React from "react";
import {Image} from "antd";
import QRCode from "react-qr-code";
import Barcode from "react-barcode";

export default class Receipt extends React.Component {


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data !== prevState.data || nextProps.id !== prevState.id) {
            //console.log(prevState, nextProps)
            return {
                data: nextProps.data,
                id: nextProps.id,
            }

        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            id: props.id,
        };
        // console.log(this.state)
    }

    render() {
        // console.log(this.state)
        if (this.state.id === 'main') {
            return (
                <div style={{marginTop: 32, maxWidth: 300, height: 'fit-content'}} className={'zig-zag'}>
                    <div>
                        {
                            this.state.data.items.filter(item => item.type === 'logo' && item.entity === this.state.id && item.value !== 'none').length > 0 ?
                                <div className={'receiptItemLogo receiptItem center'}>
                                    <Image
                                        style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                                        preview={false}
                                        src={this.state.data.gallery.filter(item => item._id === this.state.data.items.filter(item => item.type === 'logo' && item.entity === this.state.id && item.value !== 'none')[0].value).length > 0 ? this.state.data.gallery.filter(item => item._id === this.state.data.items.filter(item => item.type === 'logo' && item.entity === this.state.id && item.value !== 'none')[0].value)[0].url : false}
                                    />
                                </div> : false
                        }
                        {this.state.data.items.filter(item => item.type !== 'logo' && item.entity === this.state.id && item.position === 'top').length > 0 ?
                            this.state.data.items.filter(item => item.type !== 'logo' && item.entity === this.state.id && item.position === 'top').sort((a, b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0)).map(item => {


                                if (item.type === 'qr') {
                                    return (
                                        <div className={'receiptItem center'}>
                                            <QRCode size={128} value={item.value.length > 180 ? item.value.slice(0,180) : item.value}/>
                                        </div>
                                    )
                                } else if (item.type === 'code') {
                                    return (
                                        <div className={'receiptItem center'}>
                                            <Barcode height={40} format={'EAN13'} width={1}
                                                     value={parseInt(item.value)}/>
                                        </div>
                                    )
                                } else if (item.type === 'file') {
                                    return (
                                        <div className={'receiptItem center'}>
                                            <Image
                                                width={128}
                                                height={128}
                                                preview={false}
                                                style={{display: 'flex', justifyContent: 'center'}}
                                                src={this.state.data.gallery.filter(itemSF => itemSF._id === item.value).length > 0 ? this.state.data.gallery.filter(itemSF => itemSF._id === item.value)[0].url : false}
                                            />
                                        </div>
                                    )
                                } else if (item.type === 'string' || item.type === 'user') {
                                    return (
                                        <div className={'receiptItem left'}>
                                            <div style={item.description === 'icon' || item.description === 'file' ? {
                                                display: 'flex',
                                                alignItems: 'start'
                                            } : {}}>
                                                {item.description !== 'none' ? item.description === 'string' ? <span
                                                    style={{
                                                        marginRight: 10,
                                                        wordBreak: 'break-all'
                                                    }}>{item.descriptionValue}</span> : item.description === 'icon' ?
                                                    <Image
                                                        width={32}
                                                        height={32}
                                                        preview={false}
                                                        style={{marginRight: 10}}
                                                        src={this.props.icons.filter(itemI => itemI.uid === item.descriptionValue)[0].url}
                                                    /> : item.description === 'file' ? <Image
                                                        width={32}
                                                        height={32}
                                                        preview={false}
                                                        style={{marginRight: 10}}
                                                        src={this.state.data.gallery.filter(itemI => itemI._id === item.descriptionValue)[0].url}
                                                    /> : false : false}
                                                {item.type === 'string' ?
                                                    <span style={{wordBreak: 'break-all'}}>{item.value}</span> :
                                                    <span>Текущий пользователь</span>}
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className={'receiptItem left'}>
                                            {item.value}
                                        </div>
                                    )
                                }

                            }) : false

                        }
                        <div className={'receiptItemStatic center'}>
                            <span>КАССОВЫЙ ЧЕК</span>

                        </div>
                        <div>
                            <div className={'receiptItemStatic'}>
                                <span>Приход</span>
                            </div>
                            <div className={'receiptItemStatic center'}>
                                <span>Продажа №21 Смена №3</span>
                            </div>
                            <div className={'receiptItemStatic left'}>
                                <span>Услуга/ товар</span>
                            </div>
                            <div className={'receiptItemStatic right'}>
                                <span>1.000 x 777.00 =777.00</span>
                            </div>
                            <div className={'receiptItemStatic distribute total'}>
                                <span>ИТОГ</span>
                                <span>=777.00</span>
                            </div>
                            <div className={'receiptItemStatic distribute'}>
                                <span>Сумма БЕЗ НДС</span>
                                <span>=777.00</span>
                            </div>
                            <div className={'receiptItemStatic distribute'}>
                                <span>НАЛИЧНЫМИ</span>
                                <span>=777.00</span>
                            </div>
                        </div>
                        <div className={'receiptItemStatic center'}>
                            <span>--------------------</span>
                        </div>
                        <div className={'receiptItemStatic receiptItemStaticFooter left'}>
                            <>
                                <div><span>Магазин</span></div>
                                <div><span>Адрес</span></div>
                                <div><span>СНО</span></div>
                                <div><span>ИНН</span></div>
                                <div className={'receiptItem center'}>
                                    <QRCode size={128} value={'t=20201111T2034&s=1.00&fn=5768532753752783257427&i=4391&fp=401753497&n=1'}/>
                                </div>
                            </>
                        </div>
                        <div className={'receiptItemStatic center'}>
                            <span>--------------------</span>
                        </div>



                        {this.state.data.items.filter(item => item.type !== 'logo' && item.entity === this.state.id && item.position === 'bottom').length > 0 ?
                            this.state.data.items.filter(item => item.type !== 'logo' && item.entity === this.state.id && item.position === 'bottom').sort((a, b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0)).map(item => {


                                if (item.type === 'qr') {
                                    return (
                                        <div className={'receiptItem center'}>
                                            <QRCode size={128} value={item.value.length > 180 ? item.value.slice(0,180) : item.value}/>
                                        </div>
                                    )
                                } else if (item.type === 'code') {
                                    return (
                                        <div className={'receiptItem center'}>
                                            <Barcode height={40} format={'EAN13'} width={1}
                                                     value={parseInt(item.value)}/>
                                        </div>
                                    )
                                } else if (item.type === 'file') {
                                    return (
                                        <div className={'receiptItem center'}>
                                            <Image
                                                width={128}
                                                height={128}
                                                preview={false}
                                                style={{display: 'flex', justifyContent: 'center'}}
                                                src={this.state.data.gallery.filter(itemSF => itemSF._id === item.value).length > 0 ? this.state.data.gallery.filter(itemSF => itemSF._id === item.value)[0].url : false}
                                            />
                                        </div>
                                    )
                                } else if (item.type === 'string' || item.type === 'user') {
                                    return (
                                        <div className={'receiptItem left'}>
                                            <div style={item.description === 'icon' || item.description === 'file' ? {
                                                display: 'flex',
                                                alignItems: 'start'
                                            } : {}}>
                                                {item.description !== 'none' ? item.description === 'string' ? <span
                                                    style={{
                                                        marginRight: 10,
                                                        wordBreak: 'break-all'
                                                    }}>{item.descriptionValue}</span> : item.description === 'icon' ?
                                                    <Image
                                                        width={32}
                                                        height={32}
                                                        preview={false}
                                                        style={{marginRight: 10}}
                                                        src={this.props.icons.filter(itemI => itemI.uid === item.descriptionValue)[0].url}
                                                    /> : item.description === 'file' ? <Image
                                                        width={32}
                                                        height={32}
                                                        preview={false}
                                                        style={{marginRight: 10}}
                                                        src={this.state.data.gallery.filter(itemI => itemI._id === item.descriptionValue)[0].url}
                                                    /> : false : false}
                                                {item.type === 'string' ?
                                                    <span style={{wordBreak: 'break-all'}}>{item.value}</span> :
                                                    <span>Текущий пользователь</span>}
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className={'receiptItem left'}>
                                            {item.value}
                                        </div>
                                    )
                                }

                            }) : false

                        }

                    </div>
                </div>
            )
        } else {


            return (
                <div style={{marginTop: 32, maxWidth: 300, height: 'fit-content'}} className={'zig-zag'}>
                    <div>
                        {
                            this.state.data.items.filter(item => item.type === 'logo' && item.entity === this.state.id && item.value !== 'none').length > 0 ?
                                <div className={'receiptItemLogo receiptItem center'}>
                                    <Image
                                        style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                                        preview={false}
                                        src={this.state.data.gallery.filter(item => item._id === this.state.data.items.filter(item => item.type === 'logo' && item.entity === this.state.id && item.value !== 'none')[0].value).length > 0 ? this.state.data.gallery.filter(item => item._id === this.state.data.items.filter(item => item.type === 'logo' && item.entity === this.state.id && item.value !== 'none')[0].value)[0].url : false}
                                    />
                                </div> : this.state.data.items.filter(item => item.type === 'logo' && item.entity === this.state.id && item.value === 'none').length > 0 ? false : this.state.data.items.filter(item => item.type === 'logo' && item.entity === 'main' && item.value !== 'none').length > 0 ?
                                <div className={'receiptItemLogo receiptItem center'}>
                                    <Image
                                        style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
                                        preview={false}
                                        src={this.state.data.gallery.filter(item => item._id === this.state.data.items.filter(item => item.type === 'logo' && item.entity === 'main' && item.value !== 'none')[0].value).length > 0 ? this.state.data.gallery.filter(item => item._id === this.state.data.items.filter(item => item.type === 'logo' && item.entity === 'main' && item.value !== 'none')[0].value)[0].url : false}
                                    />
                                </div> : false

                            /* this.state.data.items.filter(item => item.type === 'logo' && item.entity === this.state.id && item.value !== 'none').length > 0 ?
                                 <div className={'receiptItemLogo receiptItem center'}>
                                     <Image
                                         style={{display: 'flex', justifyContent: 'center'}}
                                         preview={false}
                                         src={this.state.data.gallery.filter(item => item._id === this.state.data.items.filter(item => item.type === 'logo' && item.entity === this.state.id && item.value !== 'none')[0].value).length > 0 ? this.state.data.gallery.filter(item => item._id === this.state.data.items.filter(item => item.type === 'logo' && item.entity === this.state.id && item.value !== 'none')[0].value)[0].url : false}
                                     />
                                 </div> : this.state.data.items.filter(item => item.type === 'logo' && item.entity === 'main' && item.value !== 'none').length > 0 ?
                                 <div className={'receiptItemLogo receiptItem center'}>
                                     <Image
                                         style={{display: 'flex', justifyContent: 'center'}}
                                         preview={false}
                                         src={this.state.data.gallery.filter(item => item._id === this.state.data.items.filter(item => item.type === 'logo' && item.entity === 'main' && item.value !== 'none')[0].value).length > 0 ? this.state.data.gallery.filter(item => item._id === this.state.data.items.filter(item => item.type === 'logo' && item.entity === 'main' && item.value !== 'none')[0].value)[0].url : false}
                                     />
                                 </div> : false*/


                        }
                        {
                            this.state.data.items.filter(item => (item.type !== 'logo' && item.entity === this.state.id && item.position === 'top') || (item.type !== 'logo' && item.entity === 'main' && item.position === 'top')).length > 0 ?
                                this.state.data.items.filter(item => (item.type !== 'logo' && item.entity === this.state.id && item.position === 'top') || (item.type !== 'logo' && item.entity === 'main' && item.position === 'top')).sort((a, b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0)).map(item => {
                                    let show = false
                                    if (item.entity !== 'main') {
                                        show = true
                                    }
                                    if (this.state.data.exceptions.filter(ex => ex.entity === this.state.id).length > 0) {
                                        if (this.state.data.exceptions.filter(ex => ex.entity === this.state.id)[0].items.indexOf(item._id) < 0) {
                                            //эксепшена нет - можно показать
                                            show = true
                                        }
                                    } else {
                                        show = true
                                    }

                                    if (show) {

                                        if (item.type === 'qr') {
                                            return (
                                                <div className={'receiptItem center'}>
                                                    <QRCode size={128} value={item.value.length > 180 ? item.value.slice(0,180) : item.value}/>
                                                </div>
                                            )
                                        } else if (item.type === 'code') {
                                            return (
                                                <div className={'receiptItem center'}>
                                                    <Barcode height={40} format={'EAN13'} width={1}
                                                             value={parseInt(item.value)}/>
                                                </div>
                                            )
                                        } else if (item.type === 'file') {
                                            return (
                                                <div className={'receiptItem center'}>
                                                    <Image
                                                        width={128}
                                                        height={128}
                                                        preview={false}
                                                        style={{display: 'flex', justifyContent: 'center'}}
                                                        src={this.state.data.gallery.filter(itemSF => itemSF._id === item.value).length > 0 ? this.state.data.gallery.filter(itemSF => itemSF._id === item.value)[0].url : false}
                                                    />
                                                </div>
                                            )
                                        } else if (item.type === 'string' || item.type === 'user') {
                                            return (
                                                <div className={'receiptItem left'}>
                                                    <div
                                                        style={item.description === 'icon' || item.description === 'file' ? {
                                                            display: 'flex',
                                                            alignItems: 'start'
                                                        } : {}}>
                                                        {item.description !== 'none' ? item.description === 'string' ?
                                                            <span
                                                                style={{
                                                                    marginRight: 10,
                                                                    wordBreak: 'break-all'
                                                                }}>{item.descriptionValue}</span> : item.description === 'icon' ?
                                                                <Image
                                                                    width={32}
                                                                    height={32}
                                                                    preview={false}
                                                                    style={{marginRight: 10}}
                                                                    src={this.props.icons.filter(itemI => itemI.uid === item.descriptionValue)[0].url}
                                                                /> : item.description === 'file' ? <Image
                                                                    width={32}
                                                                    height={32}
                                                                    preview={false}
                                                                    style={{marginRight: 10}}
                                                                    src={this.state.data.gallery.filter(itemI => itemI._id === item.descriptionValue)[0].url}
                                                                /> : false : false}
                                                        {item.type === 'string' ?
                                                            <span style={{wordBreak: 'break-all'}}>{item.value}</span> :
                                                            <span>Текущий пользователь</span>}
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className={'receiptItem left'}>
                                                    {item.value}
                                                </div>
                                            )
                                        }


                                    } else {
                                        return false
                                    }
                                })
                                : false
                        }
                        <div className={'receiptItemStatic center'}>
                            <span>КАССОВЫЙ ЧЕК</span>

                        </div>
                        <div>
                            <div className={'receiptItemStatic'}>
                                <span>Приход</span>
                            </div>
                            <div className={'receiptItemStatic center'}>
                                <span>Продажа №21 Смена №3</span>
                            </div>
                            <div className={'receiptItemStatic left'}>
                                <span>Услуга/ товар</span>
                            </div>
                            <div className={'receiptItemStatic right'}>
                                <span>1.000 x 777.00 =777.00</span>
                            </div>
                            <div className={'receiptItemStatic distribute total'}>
                                <span>ИТОГ</span>
                                <span>=777.00</span>
                            </div>
                            <div className={'receiptItemStatic distribute'}>
                                <span>Сумма БЕЗ НДС</span>
                                <span>=777.00</span>
                            </div>
                            <div className={'receiptItemStatic distribute'}>
                                <span>НАЛИЧНЫМИ</span>
                                <span>=777.00</span>
                            </div>
                        </div>
                        <div className={'receiptItemStatic center'}>
                            <span>--------------------</span>
                        </div>
                        <div className={'receiptItemStatic receiptItemStaticFooter left'}>
                            {
                                this.state.data.shops.map(shop => {
                                    if (shop.terminals.filter(term => term.uuid === this.state.id).length > 0) {
                                        return (
                                            <>
                                                <div><span>{shop.name}</span></div>
                                                <div><span>{shop.address}</span></div>
                                                <div><span>СНО</span></div>
                                                <div><span>ИНН</span></div>
                                                <div className={'receiptItem center'}>
                                                    <QRCode size={128} value={'t=20201111T2034&s=1.00&fn=5768532753752783257427&i=4391&fp=401753497&n=1'}/>
                                                </div>
                                            </>
                                        )

                                    }
                                    return null
                                })
                            }
                        </div>
                        <div className={'receiptItemStatic center'}>
                            <span>--------------------</span>
                        </div>
                        {
                            this.state.data.items.filter(item => (item.type !== 'logo' && item.entity === this.state.id && item.position === 'bottom') || (item.type !== 'logo' && item.entity === 'main' && item.position === 'bottom')).length > 0 ?
                                this.state.data.items.filter(item => (item.type !== 'logo' && item.entity === this.state.id && item.position === 'bottom') || (item.type !== 'logo' && item.entity === 'main' && item.position === 'bottom')).sort((a, b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0)).map(item => {
                                    let show = false
                                    if (item.entity !== 'main') {
                                        show = true
                                    }
                                    if (this.state.data.exceptions.filter(ex => ex.entity === this.state.id).length > 0) {
                                        if (this.state.data.exceptions.filter(ex => ex.entity === this.state.id)[0].items.indexOf(item._id) < 0) {
                                            //эксепшена нет - можно показать
                                            show = true
                                        }
                                    } else {
                                        show = true
                                    }

                                    if (show) {

                                        if (item.type === 'qr') {
                                            return (
                                                <div className={'receiptItem center'}>
                                                    <QRCode size={128} value={item.value.length > 180 ? item.value.slice(0,180) : item.value}/>
                                                </div>
                                            )
                                        } else if (item.type === 'code') {
                                            return (
                                                <div className={'receiptItem center'}>
                                                    <Barcode height={40} format={'EAN13'} width={1}
                                                             value={parseInt(item.value)}/>
                                                </div>
                                            )
                                        } else if (item.type === 'file') {
                                            return (
                                                <div className={'receiptItem center'}>
                                                    <Image
                                                        width={128}
                                                        height={128}
                                                        preview={false}
                                                        style={{display: 'flex', justifyContent: 'center'}}
                                                        src={this.state.data.gallery.filter(itemSF => itemSF._id === item.value).length > 0 ? this.state.data.gallery.filter(itemSF => itemSF._id === item.value)[0].url : false}
                                                    />
                                                </div>
                                            )
                                        } else if (item.type === 'string' || item.type === 'user') {
                                            return (
                                                <div className={'receiptItem left'}>
                                                    <div
                                                        style={item.description === 'icon' || item.description === 'file' ? {
                                                            display: 'flex',
                                                            alignItems: 'start'
                                                        } : {}}>
                                                        {item.description !== 'none' ? item.description === 'string' ?
                                                            <span
                                                                style={{
                                                                    marginRight: 10,
                                                                    wordBreak: 'break-all'
                                                                }}>{item.descriptionValue}</span> : item.description === 'icon' ?
                                                                <Image
                                                                    width={32}
                                                                    height={32}
                                                                    preview={false}
                                                                    style={{marginRight: 10}}
                                                                    src={this.props.icons.filter(itemI => itemI.uid === item.descriptionValue)[0].url}
                                                                /> : item.description === 'file' ? <Image
                                                                    width={32}
                                                                    height={32}
                                                                    preview={false}
                                                                    style={{marginRight: 10}}
                                                                    src={this.state.data.gallery.filter(itemI => itemI._id === item.descriptionValue)[0].url}
                                                                /> : false : false}
                                                        {item.type === 'string' ?
                                                            <span style={{wordBreak: 'break-all'}}>{item.value}</span> :
                                                            <span>Текущий пользователь</span>}
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className={'receiptItem left'}>
                                                    {item.value}
                                                </div>
                                            )
                                        }


                                    } else {
                                        return false
                                    }
                                })
                                : false
                        }



                    </div>
                </div>
            )
        }

    }

}