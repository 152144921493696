import React from 'react';
import {Table, Input, message, DatePicker, InputNumber, Form, Row, Col, Button, Select, Tag} from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import Axios from "axios";


const {RangePicker} = DatePicker;
const {Option} = Select;
const dateFormat = 'DD.MM.YYYY';
const timeFormat = 'HH:mm';
const conditionMap = {
    eq: '= Равно',
    gr: '> Больше',
    less: '< Меньше',
    range: '> < Промежуток',

}
const eventMap = {
    date: 'Дата',
    cost: 'Сумма чека',
    random: 'Случайная вероятность(%)',
    days: 'Дни недели',
    hours: 'Время',
}
const dayMap = [
    'ПН',
    'ВТ',
    'СР',
    'ЧТ',
    'ПТ',
    'СБ',
    'ВС',
]

export default class RulesTable extends React.Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.data !== nextProps.data) {
            return {
                data: nextProps.data
            };
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            editingKey: '',
            currentName: '',
            currentType: '',
            currentCondition: '',
            currentValueF: '',
            currentValueS: '',
            errorName: false,
            errorType: false,
            errorCondition: false,
            errorValueF: false,
            errorValueS: false,
        }
    }

    edit = (row) => {
        this.setState({

            currentName: row.name,
            currentType: row.type,
            currentCondition: row.condition,
            currentValueF: row.valueF,
            currentValueS: row.valueS,
        }, () => {
            this.setState({
                editingKey: row.key,
            })
        })
    }
    save = (key) => {
        if(this.state.currentName.length < 1) {
            this.setState({
                errorName: true
            })
        } else {
            this.setState({
                errorName: false
            })
        }
        if(this.state.currentCondition.length < 1) {
            this.setState({
                errorCondition: true
            })
        } else {
            this.setState({
                errorCondition: false
            })
        }
        if(this.state.currentType.length < 1) {
            this.setState({
                errorType: true
            })
        } else {
            this.setState({
                errorType: false
            })
        }
        if (this.state.currentValueF === null) {
            this.setState({
                errorValueF: true
            })
        } else {
            if(this.state.currentValueF.length < 1) {
                this.setState({
                    errorValueF: true
                })
            } else {
                this.setState({
                    errorValueF: false
                })
            }
        }

        if (this.state.currentCondition === 'range') {
            if (this.state.currentValueS === null) {
                this.setState({
                    errorValueS: true
                })
            } else {
                if (this.state.currentValueS === '') {
                    this.setState({
                        errorValueS: true
                    })
                } else {
                    this.setState({
                        errorValueS: false
                    })
                }
            }

        }

        if (!this.state.errorName && !this.state.errorCondition && !this.state.errorType && !this.state.errorValueF && !this.state.errorValueS) {
            try {
                Axios.patch('/api/v1/rule/' + key, {
                    headers: {},
                    data: {
                        token: this.state.data.token,
                        name: this.state.currentName,
                        condition: this.state.currentCondition,
                        type: this.state.currentType,
                        valueF: this.state.currentValueF,
                        valueS: this.state.currentValueS,

                    }
                })
                    .then(data => {
                        this.setState({
                            editingKey: '',
                            currentName: '',
                            currentType: '',
                            currentCondition: '',
                            currentValueF: '',
                            currentValueS: '',
                            errorName: false,
                            errorType: false,
                            errorCondition: false,
                            errorValueF: false,
                            errorValueS: false,
                        })
                        this.props.reloadRules()
                    })
                    .catch(err => {
                    })
            } catch (errInfo) {
               // console.log('Validate Failed:', errInfo);
            }
        }

    }
    cancel = () => {
        this.setState({
            editingKey: '',
            currentName: '',
            currentType: '',
            currentCondition: '',
            currentValueF: '',
            currentValueS: '',
            errorName: false,
            errorType: false,
            errorCondition: false,
            errorValueF: false,
            errorValueS: false,
        })
    }
    deleteRow = (record) => {
        if (record.key) {
            Axios.delete('/api/v1/rule/' + record.key, {
                headers: {},
                data: {
                    token: this.state.data.token
                }
            })
                .then(data => {
                    if(data.data.exist) {
                        message.error('Правило используется');
                    } else {
                        this.setState({
                            editingKey: '',
                            currentName: '',
                            currentType: '',
                            currentCondition: '',
                            currentValueF: '',
                            currentValueS: '',
                            errorName: false,
                            errorType: false,
                            errorCondition: false,
                            errorValueF: false,
                            errorValueS: false,
                        })
                        this.props.reloadRules()
                    }
                })
                .catch(err => {

                })
        }
    }

    handleAdd = () => {
        Axios.post('/api/v1/rule/', {
            token: this.state.data.token,
            uid: this.state.data.uid,
            name: `Новое условие`,
            type: 'cost',
            condition: 'eq',
            valueF: 1,
            valueS: '',
        },)
            .then(data => {
                this.setState({
                    editingKey: '',
                    currentName: '',
                    currentType: '',
                    currentCondition: '',
                    currentValueF: '',
                    currentValueS: '',
                    errorName: false,
                    errorType: false,
                    errorCondition: false,
                    errorValueF: false,
                    errorValueS: false,
                })
                this.props.reloadRules()
            })
            .catch(err => {

            })
    };

    render() {


        const columns = [
            {
                title: 'Название',
                dataIndex: 'name',
                width: '20%',
                editable: true,
            },
            {
                title: 'Тип',
                dataIndex: 'type',
                width: '20%',
                editable: true,
                render: (key) => (<span>{eventMap[key]}</span>)
            },
            {
                title: 'Условие',
                dataIndex: 'condition',
                width: '20%',
                editable: true,
                render: (key) => (<span>{conditionMap[key]}</span>)
            },
            {
                title: 'Значение условия',
                dataIndex: 'valueF',
                width: '20%',
                editable: true,
                render: (_, record) => {
                    let resp = <></>
                    if (record.condition !== 'range') {
                        if (record.type === 'date') {
                            resp = <span>{moment(_).format(dateFormat)}</span>
                        } else if (record.type === 'hours') {
                            resp = <span>{moment(_).format(timeFormat)}</span>
                        } else {
                            resp = <span>{_}</span>
                        }
                    } else {
                        if (record.type === 'date') {
                            resp =
                                <span>{moment(_).format(dateFormat)} - {moment(record.valueS).format(dateFormat)}</span>
                        } else if (record.type === 'hours') {
                            resp =
                                <span>{moment(_).format(timeFormat)} - {moment(record.valueS).format(timeFormat)}</span>
                        } else {
                            resp = <span>{_} - {record.valueS}</span>
                        }

                    }

                    return (resp)
                }
            },
            {
                title: 'Действия',
                dataIndex: 'operation',
                render: (_, record) => {
                    return (
                        <div>
                            <Tag style={{
                                marginRight: 8,
                                cursor: this.state.editingKey === '' ? 'pointer' : 'not-allowed'
                            }} disabled={this.state.editingKey !== ''} onClick={() => {
                                if (this.state.editingKey === '') {
                                    this.edit(record)
                                }

                            }} color="cyan">Изменить</Tag>
                            <Tag disabled={this.state.editingKey === record.key} onClick={() => {
                                if (this.state.editingKey === record.key) {
                                    this.deleteRow(record)
                                }
                            }} style={{
                                cursor: 'pointer'
                            }} color="red">Удалить</Tag>

                        </div>
                    );
                },
            },
        ];

        return (
            <Row style={{marginTop: 24}}>
                <Col span={24}>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button disabled={this.state.editingKey !== ''} onClick={() => {
                            this.handleAdd()
                        }} type="primary"
                                style={{marginBottom: 16, height: 40}}>
                            Новое правило
                        </Button>
                    </div>
                </Col>
                {this.state.editingKey !== '' ?
                    <Col style={{marginBottom: 24}} span={24}>
                        <Form
                            layout={'inline'}
                        >
                            <Form.Item help={this.state.errorName ? "Введите название" : ""} validateStatus={this.state.errorName ? "error" : "success"} label="Название">
                                <Input onChange={(e) => {
                                    this.setState({
                                        currentName: e.target.value
                                    })
                                    if (e.target.value.length < 1) {
                                        this.setState({
                                            errorName:true
                                        })
                                    } else {
                                        this.setState({
                                            errorName:false
                                        })
                                    }
                                }} value={this.state.currentName} placeholder="Введите название"/>
                            </Form.Item>
                            <Form.Item help={this.state.errorType ? "Укажите тип" : ""} validateStatus={this.state.errorType ? "error" : "success"}  label="Тип">
                                <Select onChange={(e) => {
                                    this.setState({
                                        currentCondition: 'eq',
                                        currentValueS: '',
                                        currentValueF: '',
                                        currentType: e
                                    })
                                    if (e.length < 1) {
                                        this.setState({
                                            errorType:true
                                        })
                                    } else {
                                        this.setState({
                                            errorType:false
                                        })
                                    }
                                }} value={this.state.currentType}>
                                    <Option value="date">{eventMap.date}</Option>
                                    <Option value="cost">{eventMap.cost}</Option>
                                    <Option value="random">{eventMap.random}</Option>
                                    <Option value="days">{eventMap.days}</Option>
                                    <Option value="hours">{eventMap.hours}</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item help={this.state.errorCondition ? "Укажите условие" : ""} validateStatus={this.state.errorCondition ? "error" : "success"}  label="Условие">
                                {this.state.currentType !== 'random' ?
                                    <Select value={this.state.currentCondition} onChange={(e) => {
                                        this.setState({
                                            currentCondition: e,
                                        })
                                        if (e.length < 1) {
                                            this.setState({
                                                errorCondition:true
                                            })
                                        } else {
                                            this.setState({
                                                errorCondition:false
                                            })
                                        }
                                    }}>
                                        <Option disabled={false} value="eq">{conditionMap.eq}</Option>
                                        <Option disabled={this.state.currentType === 'days' || this.state.currentType === 'rand'} value="gr">{conditionMap.gr}</Option>
                                        <Option disabled={this.state.currentType === 'days' || this.state.currentType === 'rand'} value="less">{conditionMap.less}</Option>
                                        <Option disabled={this.state.currentType === 'days' || this.state.currentType === 'rand'} value="range">{conditionMap.range}</Option>
                                    </Select> : false
                                }
                            </Form.Item>
                            <Form.Item label="Значение">
                                {this.state.currentType === 'days' ? this.state.currentCondition === 'eq' || this.state.currentCondition === 'gr' || this.state.currentCondition === 'less' ?
                                    <Select help={this.state.errorValueF ? "Укажите значение" : ""} validateStatus={this.state.errorValueF ? "error" : "success"}  value={this.state.currentValueF} onChange={(e) => {
                                        this.setState({
                                            currentValueF: e,
                                        })
                                        if (e.length < 1) {
                                            this.setState({
                                                errorValueF:true
                                            })
                                        } else {
                                            this.setState({
                                                errorValueF:false
                                            })
                                        }
                                    }}>
                                        {dayMap.map(item => (<Option key={item} value={item}>{item}</Option>))}
                                    </Select> : false : false
                                }
                                {this.state.currentType === 'days' ? this.state.currentCondition === 'range' ?

                                    <div>
                                        <div>
                                            <span>От: <Select  help={this.state.errorValueF ? "Укажите значение" : ""} validateStatus={this.state.errorValueF ? "error" : "success"}  value={this.state.currentValueF} onChange={(e) => {
                                                this.setState({
                                                    currentValueF: e,
                                                })
                                                if (e.length < 1) {
                                                    this.setState({
                                                        errorValueF:true
                                                    })
                                                } else {
                                                    this.setState({
                                                        errorValueF:false
                                                    })
                                                }
                                            }}>
                                                {dayMap.map(item => (<Option key={item} value={item}>{item}</Option>))}
                                                </Select>
                                            </span>
                                        </div>
                                        <div>
                                            <span>До: <Select  help={this.state.errorValueS ? "Укажите значение" : ""} validateStatus={this.state.errorValueS ? "error" : "success"}  value={this.state.currentValueS} onChange={(e) => {
                                                this.setState({
                                                    currentValueS: e,
                                                })
                                                if (e.length < 1) {
                                                    this.setState({
                                                        errorValueS:true
                                                    })
                                                } else {
                                                    this.setState({
                                                        errorValueS:false
                                                    })
                                                }
                                            }}>
                                                {dayMap.map(item => (<Option key={item} value={item}>{item}</Option>))}
                                                </Select>
                                            </span>
                                        </div>
                                    </div>
                                    : false : false
                                }
                                {this.state.currentType === 'random' ?
                                    <InputNumber help={this.state.errorValueF ? "Укажите значение" : ""} validateStatus={this.state.errorValueF ? "error" : "success"}  onChange={(e) => {
                                        this.setState({
                                            currentValueF: e
                                        })
                                        if (e.length < 1) {
                                            this.setState({
                                                errorValueF:true
                                            })
                                        } else {
                                            this.setState({
                                                errorValueF:false
                                            })
                                        }
                                    }}  value={this.state.currentValueF} min={1} max={100}/> : false
                                }
                                {this.state.currentType === 'cost' ? this.state.currentCondition === 'eq' || this.state.currentCondition === 'gr' || this.state.currentCondition === 'less' ?
                                    <InputNumber  help={this.state.errorValueF ? "Укажите значение" : ""} validateStatus={this.state.errorValueF ? "error" : "success"}  onChange={(e) => {
                                        this.setState({
                                            currentValueF: e
                                        })
                                        if (e.length < 1) {
                                            this.setState({
                                                errorValueF:true
                                            })
                                        } else {
                                            this.setState({
                                                errorValueF:false
                                            })
                                        }
                                    }}  value={this.state.currentValueF} min={1}
                                                 max={10000000000000000000}/> : false : false
                                }
                                {this.state.currentType === 'cost' ? this.state.currentCondition === 'range' ?
                                    <div>
                                        <div>
                                            <span>От: <InputNumber help={this.state.errorValueF ? "Укажите значение" : ""} validateStatus={this.state.errorValueF ? "error" : "success"} value={this.state.currentValueF} onChange={(e) => {
                                                this.setState({
                                                    currentValueF: e
                                                })
                                                if (e.length < 1) {
                                                    this.setState({
                                                        errorValueF:true
                                                    })
                                                } else {
                                                    this.setState({
                                                        errorValueF:false
                                                    })
                                                }
                                            }} min={1} max={10000000000000000000}/>
                                            </span>
                                        </div>
                                        <div>
                                            <span>До: <InputNumber  help={this.state.errorValueS ? "Укажите значение" : ""} validateStatus={this.state.errorValueS ? "error" : "success"}  value={this.state.currentValueS} onChange={(e) => {
                                                this.setState({
                                                    currentValueS: e
                                                })
                                                if (e.length < 1) {
                                                    this.setState({
                                                        errorValueS:true
                                                    })
                                                } else {
                                                    this.setState({
                                                        errorValueS:false
                                                    })
                                                }
                                            }} min={1} max={10000000000000000000}/>
                                            </span>
                                        </div>
                                    </div> : false : false
                                }
                                {this.state.currentType === 'date' ? this.state.currentCondition === 'eq' || this.state.currentCondition === 'gr' || this.state.currentCondition === 'less' ?
                                    <DatePicker
                                        help={this.state.errorValueF ? "Укажите значение" : ""} validateStatus={this.state.errorValueF ? "error" : "success"}
                                        value={this.state.currentValueF === '' || this.state.currentValueF === null ? null : moment(this.state.currentValueF)}
                                        locale={locale} format={dateFormat} onChange={(e) => {
                                        this.setState({
                                            currentValueF: e
                                        })
                                        if (e === null) {
                                            this.setState({
                                                errorValueF:true
                                            })
                                        } else {
                                            if (e.length < 1 || e === null) {
                                                this.setState({
                                                    errorValueF:true
                                                })
                                            } else {
                                                this.setState({
                                                    errorValueF:false
                                                })
                                            }
                                        }

                                    }}/>
                                    : false : false
                                }
                                {this.state.currentType === 'date' ? this.state.currentCondition === 'range' ?
                                    <div>
                                        <RangePicker
                                            help={this.state.errorValueF || this.state.errorValueS ? "Укажите значение" : ""} validateStatus={this.state.errorValueF || this.state.errorValueS ? "error" : "success"}
                                            defaultValue={this.state.currentValueF !== '' && this.state.currentValueS !== '' && this.state.currentValueF !== null && this.state.currentValueS !== null  ? [moment(this.state.currentValueF), moment(this.state.currentValueS)] : [null, null]}
                                            onChange={(e) => {
                                                this.setState({
                                                    currentValueF: e[0],
                                                    currentValueS: e[1]
                                                })
                                                if (e[0] === null || e[1] === null ) {
                                                    this.setState({
                                                        errorValueF:true
                                                    })
                                                } else  {
                                                    if (e[0].length < 1 || e[0] === null) {
                                                        this.setState({
                                                            errorValueF:true
                                                        })
                                                    } else {
                                                        this.setState({
                                                            errorValueF:false
                                                        })
                                                    }
                                                    if (e[1].length < 1 || e[1] === null) {
                                                        this.setState({
                                                            errorValueS:true
                                                        })
                                                    } else {
                                                        this.setState({
                                                            errorValueS:false
                                                        })
                                                    }
                                                }

                                            }} locale={locale} format={dateFormat}/>
                                    </div> : false : false
                                }
                                {this.state.currentType === 'hours' ? this.state.currentCondition === 'eq' || this.state.currentCondition === 'gr' || this.state.currentCondition === 'less' ?
                                    <DatePicker
                                        help={this.state.errorValueF ? "Укажите значение" : ""} validateStatus={this.state.errorValueF ? "error" : "success"}
                                        value={this.state.currentValueF === '' || this.state.currentValueF === null ? null : moment(this.state.currentValueF)}
                                        locale={locale} format={timeFormat} picker={'time'} onChange={(e) => {
                                        this.setState({
                                            currentValueF: e
                                        })
                                        if (e === null) {
                                            this.setState({
                                                errorValueF:true
                                            })
                                        } else {
                                            if (e.length < 1 || e === null) {
                                                this.setState({
                                                    errorValueF:true
                                                })
                                            } else {
                                                this.setState({
                                                    errorValueF:false
                                                })
                                            }
                                        }

                                    }}/>
                                    : false : false
                                }
                                {this.state.currentType === 'hours' ? this.state.currentCondition === 'range' ?
                                    <div>
                                        <RangePicker
                                            help={this.state.errorValueF || this.state.errorValueS ? "Укажите значение" : ""} validateStatus={this.state.errorValueF || this.state.errorValueS ? "error" : "success"}
                                            defaultValue={this.state.currentValueF !== '' && this.state.currentValueS !== '' && this.state.currentValueF !== null && this.state.currentValueS !== null ? [moment(this.state.currentValueF), moment(this.state.currentValueS)] : [null, null]}
                                            onChange={(e) => {
                                                this.setState({
                                                    currentValueF: e[0],
                                                    currentValueS: e[1]
                                                })
                                                if (e[0] === null || e[1] === null) {
                                                    this.setState({
                                                        errorValueF:true
                                                    })
                                                } else {
                                                    if (e[0].length < 1 || e[0] === null) {
                                                        this.setState({
                                                            errorValueF:true
                                                        })
                                                    } else {
                                                        this.setState({
                                                            errorValueF:false
                                                        })
                                                    }
                                                    if (e[1].length < 1 || e[1] === null) {
                                                        this.setState({
                                                            errorValueS:true
                                                        })
                                                    } else {
                                                        this.setState({
                                                            errorValueS:false
                                                        })
                                                    }
                                                }
                                            }} locale={locale}  format={timeFormat} picker={'time'} />
                                    </div> : false : false
                                }
                            </Form.Item>
                        </Form>
                        <div style={{width: '100%'}}>
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <div style={{marginTop:16}}>
                                    <Button
                                        style={{marginBottom: 16, marginRight: 8, height: 40}} onClick={this.cancel}
                                        color="red">Отмена</Button>
                                    <Button onClick={() => this.save(this.state.editingKey)}
                                            type="primary"
                                            style={{marginBottom: 16, height: 40}}>Сохранить</Button>

                                </div>
                            </div>
                        </div>
                    </Col> : false
                }
                <Col span={24}>
                    <Table
                        bordered
                        size="small"
                        columns={columns}
                        dataSource={this.state.data.rules}
                        pagination={false}
                    />
                </Col>
            </Row>

        )
    }

}