import React from "react";
import Axios from "axios";
import {Upload, Row, Col, Spin, message, Modal, ConfigProvider} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import locale from 'antd/lib/locale/ru_RU';

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function getBase64Preview(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg';
    if (!isJpgOrPng) {
        message.error('Загрузите файл формата JPG/JPEG!');
    }

    const isLt2M = true// file.size / 200 / 20 < 20;

    if (!isLt2M) {
        message.error('Файл не должен весить более 2MB!');
    }

    return isJpgOrPng && isLt2M;
}


export default class Gallery extends React.Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.data !== nextProps.data || prevState.fileList !== nextProps.data.gallery) {
            return {
                data: nextProps.data,
                fileList: nextProps.data.gallery
            };
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            previewVisible: false,
            loading: false,
            previewImage: '',
            previewTitle: '',
            data: props.data,
            fileList: props.data.gallery || [],
        };
    }

    handleUploaded = (imageUrl, fileName) => {
        Axios.post('/api/v1/upload/', {
            token: this.state.data.token,
            uid: this.state.data.uid,
            url: imageUrl,
            fileName: fileName,
        },)
            .then(data => {
                this.setState({
                    loading: false,
                }, () => {
                    this.props.reloadGallery()
                })
            })
            .catch(err => {

            })
    }

    handleChange = info => {

        if (info.file.status === 'uploading') {
            this.setState({loading: true});
            return;
        }
        if (info.file.status === 'done') {

            getBase64(info.file.originFileObj, imageUrl => this.handleUploaded(imageUrl, info.file.name),);
        }
    };

    handleCancel = () => this.setState({previewVisible: false});


    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64Preview(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.fileName || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    onRemove = file => {
        Axios.delete('/api/v1/upload/'+file._id, {
            headers: {
            },
            data: {
                token: this.state.data.token
            }})
            .then(data => {
                //console.log(data)
                if(data.data.exist) {
                    message.error('Файл используется');
                } else {
                    this.props.reloadGallery()
                }

            })
            .catch(err => {

            })
        //console.log(file)
    };

    render() {
        const {previewVisible, previewImage, fileList, previewTitle} = this.state;

        const uploadButton = (
            <div>
                {this.state.loading ? <Spin/> : <div>
                    <PlusOutlined/>
                    <div style={{marginTop: 8}}>Загрузить</div>
                </div>}
            </div>

        );
        return (
            <ConfigProvider locale={locale}>
                <Row style={{marginTop: 24}}>
                    <Col>
                        <Upload
                            action="https://receipt.evoprosystem.ru/"
                            listType="picture-card"
                            fileList={fileList}
                            onChange={this.handleChange}
                            beforeUpload={beforeUpload}
                            onRemove={this.onRemove}
                            onPreview={this.handlePreview}
                        >
                            {fileList.length >= 50 ? 'Достугнуто максимальное количество файлов' : uploadButton}
                        </Upload>
                        <Modal
                            visible={previewVisible}
                            title={previewTitle}
                            footer={null}
                            onCancel={this.handleCancel}
                        >
                            <img alt="example" style={{width: '100%'}} src={previewImage}/>
                        </Modal>
                    </Col>
                </Row>
            </ConfigProvider>
        );
    }
}