import React from "react";
import Row from "antd/lib/grid/row";
import {Col} from "antd";
import Receipt from "./Receipt";
import ItemSetting from "./ItemSetting";

export default class MainSettings extends React.Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data !== prevState.data) {
            return {
                data: nextProps.data
            }

        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            data: props.data
        }
    }

    render() {
        return (
            <Row justify="end" style={{marginTop: 24}}>
                <Col xs={24} sm={24} md={24} lg={24} xl={19}>
                    <ItemSetting  icons={this.props.icons}  reloadExceptions={this.props.reloadExceptions}   reloadItems={this.props.reloadItems} setMenuKey={this.props.setMenuKey} data={this.state.data} id={'main'}/>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={5} style={{padding: '0 24px'}}>
                    <Receipt icons={this.props.icons} data={this.state.data} id={'main'}/>
                </Col>
            </Row>

        )
    }

}